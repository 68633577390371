import { useEffect } from 'react';

import BpkIconAlert from '@skyscanner/backpack-web/bpk-component-icon/lg/alert--active';
import BpkIconCalendar from '@skyscanner/backpack-web/bpk-component-icon/lg/calendar';
import BpkIconCars from '@skyscanner/backpack-web/bpk-component-icon/lg/cars';
import BpkIconChart from '@skyscanner/backpack-web/bpk-component-icon/lg/chart';
import BpkIconFlight from '@skyscanner/backpack-web/bpk-component-icon/lg/flight';
import BpkIconFlightFlexible from '@skyscanner/backpack-web/bpk-component-icon/lg/flight-flexible';
import BpkIconHotelFlexible from '@skyscanner/backpack-web/bpk-component-icon/lg/hotel-flexible';
import BpkIconHotels from '@skyscanner/backpack-web/bpk-component-icon/lg/hotels';
import BpkIconPriceTag from '@skyscanner/backpack-web/bpk-component-icon/lg/price-tag';
import BpkIconSearch from '@skyscanner/backpack-web/bpk-component-icon/lg/search';
import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';
import BpkPanel from '@skyscanner/backpack-web/bpk-component-panel';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { ACTION_TYPE, COMPONENT_NAME, LOAD_STATUS } from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';

import type { ConfidenceMessagingProps } from '@skyscanner-internal/falcon-shared-types/types/ConfidenceMessaging';

import STYLES from './ConfidenceMessagingV2.module.scss';

type ConfidenceMessagingIcon =
  ConfidenceMessagingProps['dataList'][number]['icon'];

const getIconComponent = (icon: ConfidenceMessagingIcon) => {
  switch (icon) {
    case 'flight':
      return BpkIconFlight;
    case 'cars':
      return BpkIconCars;
    case 'hotels':
      return BpkIconHotels;
    case 'calendar':
      return BpkIconCalendar;
    case 'price-tag':
      return BpkIconPriceTag;
    case 'search':
      return BpkIconSearch;
    case 'hotel-flexible':
      return BpkIconHotelFlexible;
    case 'alert':
      return BpkIconAlert;
    case 'chart':
      return BpkIconChart;
    case 'flight-flexible':
      return BpkIconFlightFlexible;
    default:
      throw Error(`Icon component not defined for icon name "${icon}"`);
  }
};

const ConfidenceMessagingV2 = (props: ConfidenceMessagingProps) => {
  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.CONFIDENCE_MESSAGING,
      load_status: props?.dataList?.length
        ? LOAD_STATUS.LOADED
        : LOAD_STATUS.INVALID,
    });
  }, [props?.dataList]);

  if (!props?.dataList?.length) {
    return null;
  }

  const { dataList } = props;

  return (
    <BpkMobileScrollContainer
      className={STYLES.ConfidenceMessagingV2}
      data-testid="confidenceMessagingV2"
    >
      <div className={STYLES.container}>
        {dataList.map(({ description, icon }) => {
          const IconComponent = getIconComponent(icon);
          return (
            <BpkPanel key={description} className={STYLES.item}>
              <div className={STYLES.icon}>
                <IconComponent />
              </div>

              <BpkText
                className={STYLES.description}
                tagName="p"
                textStyle={TEXT_STYLES.label1}
              >
                {description}
              </BpkText>
            </BpkPanel>
          );
        })}
      </div>
    </BpkMobileScrollContainer>
  );
};

export default ConfidenceMessagingV2;
